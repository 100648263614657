<template>
  <form class="form text-left" >
    <div class="form-group" >
      <input autofocus required type="text" v-model="selectedClubLocal.name" class="form-control reservation-inputs" placeholder="Enter your name">
    </div>
    <br>
    <div class="form-group" style="text-align: center;">
      <strong >Select Club</strong>
      <select name="clubs" id="clubSelection" class="form-control reservation-inputs" v-model="selectedClubLocal.club">
        <option v-for="(club, idx) in clubs" :value="club[1]" :key="idx">{{ club[1] }}</option>
      </select>
    </div>
    <br>
    <div class="form-group" style="width: 100%;">
      <date-picker 
      v-model="selectedClubLocal.date" 
      valueType="format"
      :disabled-date="notBeforeToday"
      input-class="reservation-inputs" 
      placeholder="Select Date"
      />
    </div>
    <br>
    <div class="form-group" style="text-align: center;"> 
      <strong >Select Time</strong>
      <select name="Select_0" aria-label="Reservation time" class=" form-control reservation-inputs"  v-model="selectedClubLocal.time" >
        <option value="09:30">9:30 AM</option>
        <option value="10:00">10:00 AM</option>
        <option value="10:30">10:30 AM</option>
        <option value="11:00">11:00 AM</option>
        <option value="11:30">11:30 AM</option>
        <option value="12:00">12:00 PM</option>
        <option value="12:30">12:30 PM</option>
        <option value="13:00">1:00 PM</option>
        <option value="13:30">1:30 PM</option>
        <option value="14:00">2:00 PM</option>
        <option value="14:30">2:30 PM</option>
        <option value="15:00">3:00 PM</option>
        <option value="15:30">3:30 PM</option>
        <option value="16:00">4:00 PM</option>
        <option value="16:30">4:30 PM</option>
        <option value="17:00">5:00 PM</option>
        <option value="17:30">5:30 PM</option>
        <option value="18:00">6:00 PM</option>
        <option value="18:30">6:30 PM</option>
        <option value="19:00">7:00 PM</option>
        <option value="19:30">7:30 PM</option>
        <option value="20:00">8:00 PM</option>
        <option value="20:30">8:30 PM</option>
        <option value="21:00">9:00 PM</option>
        <option value="21:30">9:30 PM</option>
        <option value="22:00">10:00 PM</option>
        <option value="22:30">10:30 PM</option>
        <option value="23:00">11:00 PM</option>
        <option value="23:30">11:30 PM</option>
      </select>
    </div>
    <br>

    <div class="form-group" style="text-align: center;">
      <strong >Group Size</strong>
      <select name="Select_1" aria-label="Reservation party size" class=" form-control reservation-inputs" v-model="selectedClubLocal.groupSize">
        <option value="1">1 person</option>
        <option value="2" selected="selected">2 people</option>
        <option value="3">3 people</option>
        <option value="4">4 people</option>
        <option value="5">5 people</option>
        <option value="6">6 people</option>
        <option value="7">7 people</option>
        <option value="8">8 people</option>
        <option value="9">9 people</option>
        <option value="10">10 people</option>
        <option value="11">11 people</option>
        <option value="12">12 people</option>
        <option value="13">13 people</option>
        <option value="14">14 people</option>
        <option value="15">15 people</option>
        <option value="16">16 people</option>
        <option value="17">17 people</option>
        <option value="18">18 people</option>
        <option value="19">19 people</option>
        <option value="20">20 people</option>
      </select>
    </div>
    <br>
    <div class="btn-group-vertical" style=" width: 100%;">  
      <button @click="onReservationsClick('SMS')" class="btn btn-primary btn-sm">RESERVE VIA SMS</button>
      
      <button @click="onReservationsClick('Phone')" class="btn btn-primary btn-sm ">RESERVE VIA WHATSAPP</button>
    </div>
  </form>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'ReservationsForm',
  components: {DatePicker},
  model: {
    prop: 'selectedClub',
    event: 'listchange'
  },
  computed: {
    selectedClubLocal: {
      get: function () {
        return this.selectedClub;
      },
      set: function (value) {
        this.$emit('listchange', value)
      }
    },
  },
  methods: {
    onReservationsClick(event) {
      let message = `RSVP:%20${this.selectedClubLocal.club}%0a--------%0aFULL_NAME:${this.selectedClubLocal.name}%20%0aDATE:%20${this.selectedClubLocal.date}%0aARRIVAL_TIME:%20${this.selectedClubLocal.time}%0aGROUP_SIZE:%20${this.selectedClubLocal.groupSize}%0a`
      let url = 'https://wa.me/524421177251';
      if (event === 'Phone') {
        url += `?text=${message}`;
        window.open(url);
      }
      if (event === 'SMS') {
        let url = 'sms:+524421177251';
        url += `&body=${message}`;
        window.open(url);
      }
      if (event === 'TG') {
        window.open('https://t.me/MarissaTP');
      }
      // this.$gtag.event('reservations', {
      //   value: 30,
      //   event_category: 'clubs',
      //   event_label: this.club
      // });
      // this.$rollbar.info(`EVENT_INFO: ${this.club}`);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  data: () => {
    return {
      time1: '',
    }
  },
  props: ['clubs','selectedClub']
}
</script>
