<template>
  <div class="content">
    <div class="row" style="background: #1f1e1f">
      <section class="text-center col-xl-8 col-sm-12">

        <HeadImage :image="djImage" title="RESERVATIONS" :color="clubColor"
                   caption="Unlock Los Cabo's Magic - Secure your spot now"></HeadImage>

        <div class="card">
          <ReservationsForm :clubs="sortedVenues" v-model="selectedClub"></ReservationsForm>
        </div>
        <br>
        <div class="card our-services">
          <img height="80" width="80" alt="contact us" class="centered"
               data-src="https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png?w=80&amp;h=80"
               src="https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png?w=80&amp;h=80"
               lazy="loaded">
          <br>
          <h3>Our services</h3>
          <ul>
            <li><strong>Effortless Reservations</strong>: Secure your place at the most popular venues with our reliable
              booking service.
            </li>
            <li><strong>Personalized Suggestions</strong>: We'll guide you to the ideal clubs and events based on your
              unique preferences.
            </li>
            <li><strong>Comprehensive Support</strong>: From the beggining to the end, we're here to answer any
              questions.
            </li>
            <li><strong>Constant Updates</strong>: We stay in touch to make sure you have all the necessary details and
              information you need.
            </li>
            <li><strong>Event Planning</strong>: Let us handle the arrangements for your special occasion.</li>
            <li><strong>Your Special Occasion</strong>: No matter what event you're planning, we'll make your occasion
              truly memorable and special.
            </li>
          </ul>

        </div>
        <br>
        <section>
          <h3 class="text-left section-header">CLUBS</h3>
          <UL>
            <li><strong>Taboo</strong>: Luxury meets bohemian charm in the perfect spot to relax and enjoy.</li>
            <li><strong>Bagatelle</strong>: Trendy eatery with vibrant Mediterranean-French cuisine and lively DJ sets.
            </li>
            <li><strong>Rosa Negra</strong>: Lively Latin American restaurant with live music and delicious food.</li>
            <li><strong>Crania</strong>: Enjoy bold flavors, fresh ingredients, and a fusion of drinks, food, music, and
              art.
            </li>
            <li><strong>Chambao</strong>: Upscale restaurant with expertly prepared meats, lively atmosphere, and
              top-notch entertainment.
            </li>
          </UL>
        </section>

        <div id="carrousel" style="margin-top: 30px">

          <Carrousel
            folder="clubs-reservations"
            color="#efefef"
          ></Carrousel>

          <SmartMenu :links="links" :logo="this.logo">
            <p>
              <strong>Have questions or need more details?</strong>
              <br>We're here to help.
            </p>
          </SmartMenu>

        </div>
        <br/>
      </section>

      <aside class=" col-4 col-xl-4 d-none d-xl-inline">

        <SmartMenu :links="links" :logo="this.logo" :primary-color="clubColor">
          <p>
            <strong>Have questions or need more details?</strong>
            <br>We're here to help.
          </p>
        </SmartMenu>

      </aside>
    </div>
  </div>
</template>

<script>
import clubsConfig from '../../common/clubsConfig';
import Carrousel from '@/components/Carrousel';
import ReservationsForm from "@/components/Reservations/ReservationsForm";
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import HeadImage from "@/components/HeadImage/HeadImage";

export default {
  name: 'ReservationsPage',
  props: ['slug'],
  metaInfo() {

    return {
      title: `Reservations · Dinner, Shows & Events.`,
      meta: [
        {
          name: 'description',
          content: `Reservations · Dinner, Shows & Events.`,
        },
        {
          name: 'keywords',
          content: `Reservations, Bookings, Cabo, San Jose Cao, Cabo San Lucas, Clubs, Nightclubs,  party, reservations, bookings, club, nightclub`,
        },
        {
          property: 'og:title',
          content: `Bookings · Reservations`,
        },
        {
          property: 'og:url',
          content: `https://www.cabo.party/reservations`,
        },
        {
          property: 'og:description',
          content: `Reservations · Upcoming Events, Tickets & Reservations.`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content: this.djImage,
        },
      ],
    };
  },
  data: () => {
    return {
      clubConfig: {},
      rsvpConfig: {},
      clubTagsConfig: {},
      selectedClub: {},
      clubColor: '#4f2d4b',
      djImage: 'https://imgix.cosmicjs.com/ec030ce0-f3e4-11ee-b555-0d0678c27dd7-Orthodox-priest-with-white-beard-listening-to-musi.jpg',
      logo:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=aquamarine,FA8072&duotone-alpha=100',
    };
  },
  methods: {},
  components: {
    HeadImage,
    ReservationsForm,
    Carrousel,
    SmartMenu
  },
  computed: {
    imageFolder: {
      get() {
        // return [...this.$store.state.venues].sort((a, b) => {
        //   return a.title.localeCompare(b.title);
        // });
        return this.selectedClub ? 'clubs-' + this.selectedClub : 'clubs-bagatelle-los-cabos';
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    sortedVenues: {
      get() {
        // return [...this.$store.state.venues].sort((a, b) => {
        //   return a.title.localeCompare(b.title);
        // });
        return this.$store.state.venues.map(x => [x.title, x.slug]);
      },
    },
  },
  created() {
    // alert( Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.$store.dispatch('getVenues', {start: 0, limit: 14});
    this.$store.dispatch('getLinks', {slug: 'los-cabos-mexico-event-calendar'});
    this.ClubsCongif = clubsConfig;
    // window.history.scrollRestoration = 'manual';
  },
}
</script>
